import { createStore } from 'vuex';

export default createStore({
  state: {
    // Initial state will be empty
  },
  mutations: {
    // Empty for now
  },
  actions: {
    // Empty for now
  },
  getters: {
    // Empty for now
  }
});
