import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { auth } from '@/firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import es from './locales/es.json';
import Cookies from 'js-cookie';

import '@/assets/main.css'; // global css
import './registerServiceWorker';

const savedLanguage = Cookies.get('language') || 'en'; // Get the user's language preference from the cookie, default to English if no cookie exists

const i18n = createI18n({
  locale: savedLanguage, // Use the saved language preference
  fallbackLocale: 'en',
  messages: {
    en,
    es,
  },
  legacy: false, // Set this to false to disable the legacy mode
});

let app;
onAuthStateChanged(auth, () => {
  if (!app) {
    // app = createApp(App).use(router).use(i18n).mount('#app');
    app = createApp(App).use(store).use(router).use(i18n).mount('#app');
  }
});
